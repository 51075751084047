<template>
  <div class="examples modal-wrapper">
    <div class="title">
      <span>{{ modalParams.sectionTitle }}</span>
      <span v-if="!modalParams.invisibleExample"> 예시</span>
    </div>
    <div class="wrapper" :class="{'explanation': modalParams.sectionName === 'fundingIntroTemplate'}">
      <div class="content" v-for="(c, idx) in getContent()" :key="idx">
        <div class="example" v-if="!modalParams.invisibleExample">예시 {{ idx + 1 }}</div>
        <div class="title" v-if="c.title">
          <span>{{ c.title }}</span>
        </div>
        <div class="texts" v-html="c.content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";

function Component(initialize) {
  this.name = "modalRewardApplyExamples";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      store.commit("tuneModal", {component, size: "md"});
    });

    const state = reactive({});
    const modalParams = store.getters.modalParams(component);

    const getContent = () => {
      switch (modalParams.sectionName) {
        case "fundingInfo":
          return [{
            title: "오프라인 맛집이 우리집 식탁에? 10분 만에 완성하는 요리 한 상",
            content: "이제는 집에서도 간편하게 레스토랑의 맛을 즐겨보세요. 손님 초대 요리로 완.전.추.천"
          }, {
            title: "우리들의 꿈을 빚는 빛! 나는 체험",
            content: "청년들이 운영하는 베이킹 클래스에 여러분을 초대합니다"
          }, {
            title: "경계 위의 예술 - 석관동 문화 공간 조성 프로젝트",
            content: "이주민의 사회적 포용 및 다양성 인식 확산을 위한 문화적 방안"
          }, {
            title: "당신의 반려 식물은 안녕하신가요?",
            content: "청년 농업인들이 만든 반려 식물 전문병원으로 놀러 오세요"
          }];
        case "fundingIntro":
        case "oldFundingIntro":
          return [{
            title: "고급 레스토랑의 맛, 이제 집에서 즐겨요.",
            content: "<p><span>안녕하세요, 과거와 미래가 공존하는 광화문역 근처에서 작은 양식 레스토랑을 운영하는 오마이입니다.</span>" +
                "<span>뒤를 돌면 멋스럽게 남아 있는 옛 풍경과 앞을 보면 빠르게 변화하는 휘황찬란한 도시의 모습을 두루 갖춘 서울에 반해 이곳에 자리 잡은 지 벌써 8년이라는 세월이 흘렀어요.</span>" +
                "<span>주중에는 주변 직장인들이, 주말에는 관광객을 비롯해 데이트하는 커플, 나들이하는 가족까지 그동안 많은 분들이 저희 레스토랑에 찾아오셨습니다.</span></p>" +
                "<p><span>저희 레스토랑은 작은 공간이라 고객분들과 종종 대화할 기회가 생기곤 합니다.</span>" +
                "<span>그중 저희 음식을 너무 먹고 싶은데 거리가 멀어 자주 못 온다는 이야기가 계속 기억에 남더라고요. 그래서 바쁘다는 핑계로 미뤄 왔던 일을 이번 크라우드펀딩을 통해 실행해 보려고 해요.</span>" +
                "<span>매일 아침 공수하는 신선한 재료만을 사용하여 밀키트를 만들었습니다. 처음부터 끝까지 모두 직접 준비하고 만드는 만큼 완성도 높은 소량의 제품만 준비했어요.</span>" +
                "<span>고급 레스토랑의 맛을 쉽고 간편하게 드실 수 있도록 정성을 다했습니다. 이제 집에서 간편하게 즐겨 보세요!</span></p>"
          }, {
            title: "경계 위의 공간 - 다문화 사회, 함께 살아가기 위한 문화적 방안",
            content: "<p><span>최근 들어 활발해진 국제교류와 외국인의 이주, 급증하는 국제결혼 등으로 인해 우리나라도 서로 다른 문화적, 민족적 배경을 가진 사람들이 모여 사는 다문화 사회로 변화하고 있습니다.</span>" +
                "<span>행정안전부가 발표한 '2021년 지방자치단체 외국인 주민 현황' 통계에 따르면 2021년 국내 거주 외국인 주민 수는 213만 명입니다. 외국인 주민 수가 53만 명이었던 2006년에서 약 4배가량 증가한 셈이죠. 특히 최근 10년 사이에 다양한 이주 집단이 증가하고 있는데요.</span>" +
                "<span>이에 따라 이주민, 난민 등 소수자를 바라보는 개인의 의식은 높아졌지만, 대부분은 여전히 이들을 우리 사회의 ’주변인‘또는’이방인‘ 으로 인식하고 있습니다.</span>" +
                "<span>한국 사회 구성원으로서 살아가고 있음에도 외국인이라는 이유로, 여성 또는 남성이라는 이유로, 특정 지역, 국가, 문화 출신이라는 이유로, 언어 능력 부족을 이유로 차별, 폭력 피해에 노출되어 있습니다.</span></p>" +
                "<p><span>저희는 그간 가려져 있던 외국인 이주민들에 대한 사회적 관심을 환기하고, 나아가 지역주민과의 교류를 통해 사회적 관계망을 형성할 수 있는 공간이 필요하다고 생각했습니다.</span>" +
                "<span>이 공간이 다양성 인식 확산을 위한 하나의 문화적 방안이 될 수 있도록 많은 관심과 후원 부탁드립니다!</span></p>"
          }, {
            title: "청년들의 온전한 쉼의 공간, 쉼터 카페",
            content: "<p><span>우리나라 청년들은 살아가는 데 있어 얼마나 많은 것을 포기할까요?</span>" +
                "<span>10년 전 연애, 결혼, 출산 세 가지를 포기한 ‘3포세대’의 청년들이 지금은 더욱 양극화된 사회·경제적 압박으로 인해 일자리, 주택 구입 등 삶의 필수 요소까지 내려놓고 있습니다.</span>" +
                "<span>이제는 포기한 것들의 수를 헤아릴 수조차 없어 등장한 신조어 ‘N포세대’가 갈수록 핍박해지는 요즘 청년들의 삶을 여실히 드러내고 있어요. 매시간마다 N포가 늘어나는 청년들을 위해 저희 쉼터 협동조합이 뭉쳤습니다.</span></p>" +
                "<p><span>이 프로젝트는 청년들이 더 이상 포기할 이유를 찾지 않길 바라는 마음에서 시작했습니다.</span>" +
                "<span>쉼터카페는 청년 조합원들이 꾸리는 공간으로, 손수 만든 빵과 음료가 준비되어 있을 뿐만 아니라 책, 영화, 글쓰기 등 여러 분야에서 모임 활동을 운영하고 있어요 비슷한 관심사를 가지고 있는 청년들이 모여 서로의 이야기를 나누고 공감하며 치유의 시간을 보내고 있습니다.</span>" +
                "<span>혼자라는 생각에 끝이라며 포기했던 청년들이 쉼터카페에서 다시 새롭게 도전하는 시작점을 찍었으면 해요.</span></p>"
          }, {
            title: "반려 식물 어디까지 알고 있나요?",
            content: "<p>코로나19로 집에 머무는 시간이 길어지고, 작은 변화로 집안에 생기를 불어넣을 수 있는 플랜테리어(Plant + Interior)가 인기를 끌면서 자연스레 반려 식물에 관한 관심과 수요가 늘어났습니다. 식물 키우기가 새로운 인기 취미로 떠오르며 관련 산업 매출도 급격하게 증가했다고 하는데요. 내년에는 현재 규모에서 무려 7배나 성장할 것으로 전망한다고 합니다.</p>" +
                "<p><span class='d-block'>그렇다면 우리는 왜 반려 식물에 이토록 애정을 쏟고 있는 걸까요?</span>" +
                "최근 연구에 따르면 식물도 감정이 있어 인간과 교감할 수 있다는 흥미로운 결과가 나왔습니다. 반려동물뿐 아니라 반려 식물도 우리와 교감을 나눌 수 있다니 너무 신기하지 않나요? 우리 삶 한 편에 자리 잡아가고 있는 반려 식물에 대해 사람들은 '집안 분위기가 밝아졌다.' (44%), '일상 속 소소한 기쁨을 준다.' (43%), '힐링이 되는 느낌이다.' (38%) 등 심리적/정서적으로 안정감과 행복감을 느꼈다고 해요. (출처: 마크로일 엠브레인)</p>"
                +
                "<p>그러나 반려 식물에 관한 관심과 수요는 늘어났는데 제대로 관리하지 못해 버려지는 식물 또한 크게 증가했다고 합니다. 그로 인해 쓰레기도 많이 늘었고요. '맑게 갠 정원'은 이러한 문제점을 개선하고자 청년 농업인들이 만든 단체로 우리에게 힐링과 에너지를 주는 반려 식물을 보다 잘 관리할 수 있는 문화를 만들고자 시작되었습니다.</p>"
          },];
        case "fundingPoint":
          return [{
            content: "<p>- 100% 생면으로 만든 파스타</p>" +
                "<p>- 매일 아침 공수해 오는 신선한 재료로 만든 수제 소스</p>" +
                "<p>요리 초보도 뚝딱 만들 수 있는 쉬운 레시피</p>",
          }, {
            content: "<p>- 더불어 살기 위한 지역문화공간 조성 프로젝트</p>" +
                "<p>- 지역과 이웃을 연결하는 다양한 커뮤니티 서비스</p>" +
                "<p>- 공간 투어부터 오프닝 파티 초대까지, 펀딩에서 만나는 특별 리워드</p>",
          }, {
            content: "<p>- 준비물이 필요 없는 간편한 베이킹 클래스</p>" +
                "<p>- 전문 상담가와 함께하는 마음 돌봄 프로그램</p>" +
                "<p>- 청년을 위한 쉼터 카페 운영</p>",
          }, {
            content: "<p>- 전문가 알려주는 반려식물 문제점</p>" +
                "<p>- 초보 식집사를 위한 다양한 클래스 운영</p>" +
                "<p>- 큐레이션을 통한 맞춤 식물 추천</p>",
          },];
        case "rewardIntro":
          return [{
            title: "국내산 재료로 건강하게, 매일 만들어 신선하게!",
            content: "<p>처음으로 선보이는 밀키트 ‘깻잎 페스토 파스타’는 저희 레스토랑의 대표 메뉴입니다. 향긋하고 부드러운 깻잎은 쌈, 장아찌, 나물 등으로 주로 즐겨 먹는데요. 우리 식탁에 자주 오르는 반찬으로 한국인이 가장 좋아하는 채소 중 하나입니다. 저희는 깻잎을 좀 더 색다르게 즐길 수는 없을까 고민하다가 건강에 좋은 가평 잣과 국내산 깻잎을 가득 넣어 더욱 고소하고 감칠맛 나는 페스토를 완성했어요. 매장에서 직접 만드는 생면과 조합은 매일 먹어도 절대 질리지 않는 맛이에요. 한국의 맛이 담긴 페스토를 경험하고 싶다면, 가볍지만 근사한 한 끼를 먹고 싶다면, 고급 레스토랑의 맛을 집에서도 느끼고 싶다면 지금 바로 펀딩에 참여해 보세요.</p>"
                +
                "<p><span class='d-block'>여기서 팁 하나!</span>"
                + "삶은 면을 차가운 물에 헹궈 냉 파스타로도 즐겨보세요. 향긋한 깻잎 향을 더 진하게 느낄 수 있어서 별미예요.</p>"
          }, {
            title: "관계의 공간 – 이방인이 아닌 우리의 이웃으로",
            content: "<p><span class='d-block'>성북구 석관동(石串洞)을 아시나요? </span>" +
                "성북구는 다수의 대사관과 다양한 국가 출신의 이주민이 밀집해 있는 지역입니다. 성북구 북동쪽 천장산 아래에 위치한 석관동은 그중에서도 다문화가정 및 결혼이주여성이 꽤 큰 비율로 거주하고 있습니다. 이처럼 다문화 흐름의 중심에 있는 성북구 석관동에서 지역주민, 예술가, 이주 노동자, 유학생 등 다양한 사람들이 한데 모일 수 있는 지역 커뮤니티 공간을 만들고자 합니다. 그곳에서 언어, 예술, 인종, 지역 등을 주제로 하는 전시 또는 프로그램을 운영하거나, 국내 이주여성, 유학생, 다문화가족의 문화 적응과 자립을 도울 수 있는 문화예술 분야 취업 교육 등을 진행할 예정입니다.</p>"
                +
                "<p><span class='d-block'>공간 구성</span>" +
                "<span class='d-block'>1층 서점(관련 분야)</span>" +
                "<span class='d-block'>2층 전시 공간(관련 분야 전시 운영/대관)</span>" +
                "<span class='d-block'>3층 커뮤니티 공간(교육, 프로그램 운영)</span>" +
                "<span class='d-block'>4층 공유 오피스</span></p>" +
                "<p>이주민이 참여하고 함께 살아가는 지역사회, 이를 위해 플랫폼의 역할을 할 수 있는 커뮤니티 공간, 그 공간에서 허브 역할을 해내는 전문성을 가진 이주민들의 공간까지, 이 모든 공간이 연결되어 사람과 예술로 관계되는 사회적 공간을 꿈꿉니다. 저희의 여정에 동참해 주세요.</p>"
          }, {
            title: "베이킹과 함께 꿈을 빚는 마음 돌봄 프로그램",
            content: "<p>이번 펀딩에서는 베이킹과 함께 즐길 수 있는 마음 돌봄 체험을 제공합니다. 3시간 동안 맛있는 빵도 만들고, 마음 건강까지 챙겨 보는 시간이 될 거예요.</p>" +
                "<p><span class='d-block'>1. 준비물 없이 몸만 오면 되는 간편한 베이킹 클래스</span>"
                + "베이킹에 참여하는 청년들이 마음 편하게 올 수 있도록 전부 준비했습니다. 정확히 계량되어 있는 재료와 10년 경력의 베이킹 클래스 선생님과 함께 달콤한 마들렌부터 고소한 소금빵까지 만들어 볼 거예요. 만들고 남은 빵은 클래스가 끝난 후 가져갈 수 있답니다. 소중한 사람에게 선물해 보세요. *베이킹 클래스에서는 마들렌, 소금빵 중 한 가지만 만들 수 있습니다. 펀딩 참여 시 원하는 종류를 메모에 적어 주세요.</p>"
                +
                "<p><span class='d-block'>2. 청년들의 마음 건강 지키기, 마음 돌봄 프로그램</span>"
                + "청년들이 직접 만든 빵을 서로 교환하면서 마음 돌봄 프로그램을 진행해요. 이 프로그램은 참여한 청년들이 전문 상담가 선생님의 주도하에 먼저 나의 솔직한 마음을 알아봐요. 이것에 관해 이야기하면서 마음의 위안을 받는 프로그램이에요. 간단한 심리검사부터 마음을 솔직하게 표현하는 방법까지 알아가요.</p>" +
                "<p>*원데이 베이킹 클래스 & 마음 돌폼 프로그램 커리큘럼</p>" +
                "<p><span class='d-block'>14:00-14:15 프로그램 소개 및 일정 안내</span>" +
                "<span class='d-block'>14:15-16:00 베이킹 클래스 진행</span>" +
                "<span class='d-block'>16:00-16:50 마음 돌봄 프로그램(정서 검사 TCI, 그림 검사 프로그램, 네 마음을 외쳐 봐!)</span>" +
                "<span class='d-block'>16:50-17:00 마무리</span></p>"
          }, {
            title: "당신의 반려 식물은 안녕하신가요?",
            content: "<p>사람이나 반려동물이 아프면 병원에 가서 진료를 보고 처방을 받아 나아지듯이, 식물 또한 전문가의 진단을 통해 시들어 방치되어 있던 모습에서 처음 우리에게 왔던 모습처럼 생기를 되찾을 수 있습니다. 여러 이유로 시들어가는 반려 식물의 문제점을 해결하고 더 많은 식물을 만나 볼 수 있는 '맑게 갠 정원'에 방문하여 힐링의 시간을 가져 보세요. 약 500평에 달하는 '맑게 갠 정원'은 반려 식물 전문병원뿐만 아니라 초보 식집사를 위한 분갈이 클래스, 희귀 식물 존, 미니 식물원, 가드닝을 위한 키트 판매까지 다채로운 경험을 할 수 있는 공간으로 조성했어요.</p>"
                +
                "<p>그리고 올해 새로운 큐레이션 서비스를 런칭했는데, 간단한 설문조사만 참여하면 전문가가 바로 분석하여 맞춤형 식물을 추천해 드려요. 관리 방법까지 자세하게 안내해 드리니 한 달도 못가 매번 반려 식물을 버릴 수밖에 없었던 식집사분들은 한 번 참여해 보세요. 365일 활짝 열려있는 '맑게 갠 정원'은 오전 7시부터 오후 5까지 운영합니다. 바로 앞에 넓은 주차장도 있으니 부담 없이 방문하여 힐링의 시간 가져보세요.</p>"
          }];
        case "plan":
          return [{
            title: "",
            content: "<p>이번 크라우드펀딩을 통해 얻은 후원금으로 오마이 레스토랑의 다양한 밀키트를 개발할 예정입니다. 저희 레스토랑의 시그니처 메뉴인 깻잎 페스토 파스타와 곁들여 먹을 수 있는 에피타이저부터 메인 디쉬까지 쉽고 간단하지만 현장의 맛을 고스란히 느낄 수 있는, 소중한 사람에게 근사한 한끼 대접할 수 있는 그런 제품으로 찾아뵙겠습니다.</p>"
          }, {
            title: "",
            content: "<p>펀딩 후원금은 공간을 조성하는 데 쓰일 예정이며, 일부는 석관동의 소외계층 가정, 다문화가정을 위해 사용될 예정입니다. 새롭게 조성되는 공간 벽면에는 펀딩 후원자 성명을 기재하겠습니다. 오픈 후에는 다양한 전시와 교육 프로그램을 운영할 계획이니, 공간이 공개될 때까지 변함없는 관심과 애정으로 지켜봐 주시길 부탁드립니다.</p>"
          }, {
            title: "",
            content: "<p>펀딩으로 모인 후원금의 일부는 자립 준비 청년들의 앞날을 응원하기 위한 지원 비용으로 사용됩니다. 단순히 말로만 약속드리는 것이 아닌, 직접 자립 준비 청년들을 만나 펀딩 후원금을 전달하겠습니다. 자세한 사항은 오마이컴퍼니 최신 소식이나 쉼터 SNS를 통해 확인하실 수 있습니다.</p>"
          }, {
            title: "",
            content: "<p>청년 농업인이 모여 만든 ‘맑게 갠 정원’은 누구보다 청년 창업의 어려움을 잘 알고 있습니다. 이번 크라우드펀딩으로 모인 후원금으로 미래 청년 농업인을 육성하고 그들에게 정원 일부를 임대할 예정입니다. 이 프로그램을 통해 초기 창업 비용에 대한 부담을 줄이고, 미래 농업인으로 성장할 수 있는 발판을 마련하고자 합니다.</p>"
          }];
        case "makerIntro":
          return [{
            title: "",
            content: "<p>오마이 레스토랑은 단순한 레스토랑을 넘어 정성스러운 요리를 간편하게 즐길 수 있는 새로운 방식을 제안합니다. 저희는 신선한 재료와 간편한 조리 방법으로 집에서도 전문가의 맛을 느낄 수 있도록 오랜 시간 노력해 왔습니다. 많은 분들의 도움으로 저희 꿈이 현실이 되어 매우 행복하고 즐겁습니다. 앞으로 더 다양한 제품을 개발하여 여러분의 일상에 다채로운 맛을 더해줄 거라 기대합니다. 많은 관심과 지지 부탁드립니다.</p>"
          }, {
            title: "",
            content: "<p>'OMC' 는 4명의 문화기획자, 문화예술교육사, 예술가, 디자이너로 구성된 프로젝트 그룹입니다. 2년 전 팀이 결성된 이후부터 개인과 국가 간 교류가 활발해진 시대에서 접하게 되는 '다문화', '정체성'을 주제로 다수의 전시를 기획했습니다. OMC의 활동이 언젠가는 사회 문제에 대한 하나의 문화적 대안이 될 수 있을 거라는 믿음으로, 다양한 참여형 프로젝트와 문화예술 교육 등을 진행해 왔어요. 세상의 눈을 바꿔 나가는 저희의 활동을 지켜봐 주세요!</p>"
          }, {
            title: "",
            content: "<p>쉼터 협동조합은 청년들이 극심한 경쟁 사회에서 지쳐 넘어지지 않도록 쉬어 갈 장소를 제공하기 위해 설립되었습니다. 연애, 결혼, 취업, 꿈 등 수없이 많은 것들을 포기하는 청년들의 미래를 지키기 위해 같은 청년들이 뭉쳤어요. 현재 겪고 있을 아픔을 누구보다 잘 알기에 서로의 마음을 다독일 수 있는 잠깐의 쉼터가 필요하다는 생각이 들었습니다. 꿈을 빚는 프로그램 펀딩을 통해 지친 청년들에게 힘이 되어 주고 싶어요. 쉼터카페는 우리 협동조합의 첫 번째 쉼터이자 통로입니다. 이번 펀딩은 쉼터카페를 더 많은 청년들에게 알릴 수 있는 기회가 될 것이며, 수익금의 일부는 자립 준비 청년들의 앞날을 응원하기 위한 지원 비용으로 사용됩니다. 펀딩 종료 후 오마이컴퍼니 최신 소식란을 통해 상세히 확인하실 수 있습니다.</p>"
          }, {
            title: "",
            content: "<p>'맑게 갠 정원'은 건강한 반려 식물 문화를 조성하고, 다양한 가드닝 프로그램을 만들어 나가는 것을 목표로 삼고 있습니다. 또한 화훼 분야에 관심은 많지만 시작하기 어려운 예비 청년 창업가를 지원하고 그들이 지닌 새로운 가치가 우리 사회에 선한 영향력이 되기를 기대합니다. \n"
                + "우리의 정원은 수많은 식물과 꿈이 함께 자라는 공간입니다. 직접 방문하여 청년 농업인을 응원하고, 아픈 반려 식물에 맞는 영양제 공급, 해충방제, 분갈이, 자리 재배치 등 꼭 맞는 처방과 다양한 종류의 식물을 만나보세요. 모든 것이 살아 숨 쉬는 공간, '맑게 갠 정원'이었습니다.</p>"
          }];
        case "fundingIntroTemplate":
          return [{
            title: "블로그 형",
            content: "<p><img class='p-lg-5' src='/assets/img/page.apply.reward.step3.funding.intro.templete.blog.type.svg' alt='블로그형 이미지 설명' style='padding: 16px'></p>" +
                "<p class='font-weight-bold'>원하는 대로 배치할 수 있는 방식이에요</p>" +
                "<p>이런 분들께 추천 드려요!</p>" +
                "<p style='text-align: left;'>" +
                "<span class='d-flex mb-2' style='gap: 8px'><span>-</span>이미 상세 페이지 디자인이 있으신 분</span>" +
                "<span class='d-flex mb-2' style='gap: 8px'><span>-</span>OMC 광고 서비스를 통해 상세 페이지 제작을 맡길 예정이신 분</span>" +
                "<span class='d-flex mb-2' style='gap: 8px'><span>-</span>기획전 등을 통해 신청함으로써 상세 페이지 제작을 지원받으시는 분</span></p>"
          }, {
            title: "카테고리 형",
            content: "<p><img class='p-lg-5' src='/assets/img/page.apply.reward.step3.funding.intro.templete.category.type.svg' alt='카테고리형 이미지 설명' style='padding: 16px'></p>" +
                "<p class='font-weight-bold'>정해진 템플릿을 통해 깔끔하게 배치하는 방식이에요</p>" +
                "<p>이런 분들께 추천 드려요!</p>" +
                "<p style='text-align: left;'>" +
                "<span class='d-flex mb-2' style='gap: 8px'><span>-</span>상세 페이지 디자인을 처음 하시는 분</span>" +
                "<span class='d-flex mb-2' style='gap: 8px'><span>-</span>상세 페이지 디자인 시안이 없으신 분</span>" +
                "<span class='d-flex mb-2' style='gap: 8px'><span>-</span>무엇을 적어야 할 지 감이 안 잡히시는 분</span></p>"
          }];
        case "productNotice":
          return [{
            title: "",
            content: "<p>" +
                "<a href=\"https://www.law.go.kr/행정규칙/전자상거래등에서의상품등의정보제공에관한고시\" class=\"mt-10 underline color-anchor\" target=\"_blank\" rel=\"noopener noreferrer\">품목별 재화 등에 관한 정보 항목 바로보기</a></p>"
          }, {
            title: "전자상거래 등에서의 상품 등의 정보제공에 관한 고시",
            content: "<p>위 링크에서 해당하는 품목별 정보 확인 후 내용을 작성해 주세요. 상품의 정보 고시는 소비자가 전자상거래 등에서 합리적인 선택을 할 수 있도록 기여하고 소비자 피해를 예방하기 위해 반드시 작성되어야 합니다.</p>"
          }];
        case "proofDocuments":
          return [{
            title: "",
            content: "<p><div class='d-flex mb-2' style='gap: 8px'><span>-</span><span>상거래 등의 경우, 통신판매업신고증을 필수로 제출해야 합니다.</span></div>"
                + "<div class='d-flex mb-2' style='gap: 8px'><span>-</span><span>펀딩 내용에 리워드의 기능, 표능, 특허, 위생 등 증빙이 필요한 내용을 작성한 경우 <span class='font-weight-bold'>[특허증, 인증서, 계약서 등 객관적인 자료]</span>를 제출해야 합니다.</span></div></p>"
          }, {
            title: "[통신판매업신고증]",
            content: "<p><div class='d-flex mb-2' style='gap: 8px'><span>-</span><span>면제 대상</span></div>"
                + "<div class='d-flex mb-2' style='gap: 8px'><span>1)</span><span>직전년도 동안 통신판매의 거래횟수가 50회 미만인 경우</span></div>"
                + "<div class='d-flex mb-2' style='gap: 8px'><sapn>2)</sapn><span>「부가가치세법」 제2조제4호의 간이과세자인 경우</span></div></p>"
          }];
        default:
          return [{
            title: "",
            content: "<p></p>"
          }];
      }
    };

    return {component, state, modalParams, getContent};
  }
});
</script>

<style lang="scss" scoped>
@import "../styles/page.modal.scss";

.examples {
  > .wrapper {
    .content {
      font-size: $px14;

      .example {
        padding-bottom: $px8;
        font-weight: 500;
        color: $colorSecondary;
      }

      .title {
        font-size: $px16;
        font-weight: 600;
        margin-bottom: $px4;
      }

      &:not(:last-child) {
        margin-bottom: $px24;
      }
    }

    &.explanation {
      display: flex;
      gap: $px24;

      > .content {
        flex: 1;
        text-align: center;
      }
    }
  }
}
</style>